import {ADD_Local_ROW_MOVEMENTS_TABLE,UPDATE_Local_ROW_MOVEMENTS_TABLE,Reset_Local_ROW_MOVEMENTS_TABLE} from '../../../../constants/ActionTypes'

const InitialState = {
    LocalPurchaseTableData:[],
    LocalPurchaseHeader:[]
}

function LocalPurchase(state = InitialState, action) {
    switch (action.type) {
        case ADD_Local_ROW_MOVEMENTS_TABLE:
        return{
            ...state,
            LocalPurchaseHeader:[...state.LocalPurchaseHeader,action.payload]          
        }

        case UPDATE_Local_ROW_MOVEMENTS_TABLE:
            let NewData=state.LocalPurchaseHeader.map((data, index)=>(index != action.payload.index ?  data : ({
                ...data,
                ...action.payload.data
              })));
              return {
                  ...state,
                  LocalPurchaseHeader:NewData
        }
        case Reset_Local_ROW_MOVEMENTS_TABLE:
            return {
                ...state,
                LocalPurchaseHeader:[]
            }
            
        default:
          return state
    }

}

export default LocalPurchase;