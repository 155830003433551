import {getRequest} from './Axios'
import {GET_LAST_CONTABLE_CLOSING_DATE,GET_NEXT_CONTABLE_CLOSING_DATE,GET_LAST_Depreciation_CLOSING_DATE,GET_NEXT_Depreciation_CLOSING_DATE} from '../../constants/ActionTypes'
import {CLOSING_TYPE_ACCOUNTING_ID,CLOSING_TYPE_DEPRECIATION_ID} from '../../constants/Config'

export const GetContableClosingDate=()=>(dispatch)=>{
    dispatch(getRequest("/ClosingDate/GetLastClosingDates",{ClosingTypeId:CLOSING_TYPE_ACCOUNTING_ID}))
    .then((resp)=>{
       const {payload:{data}}=resp;
        dispatch({
            type:GET_LAST_CONTABLE_CLOSING_DATE,
            payload:{
                ClosingDate:data.length>0?data[0]:""
            }
        })
    })
    .catch((error=>console.error(error)))
}

export const GetDepreciationClosingDate=()=>(dispatch)=>{
    dispatch(getRequest("/ClosingDate/GetLastClosingDates",{ClosingTypeId:CLOSING_TYPE_DEPRECIATION_ID}))
    .then((resp)=>{
       const {payload:{data}}=resp;
        dispatch({
            type:GET_LAST_Depreciation_CLOSING_DATE,
            payload:{
                ClosingDate:data.length>0?data[0]:""
            }
        })
    })
    .catch((error=>console.error(error)))
}



export const GetNextContableClosingDate=()=>(dispatch)=>{
    dispatch(getRequest("/ClosingDate/NextClosingDate",{ClosingTypeId:CLOSING_TYPE_ACCOUNTING_ID}))
    .then((resp)=>{
       const {payload:{data}}=resp;
        dispatch({
            type:GET_NEXT_CONTABLE_CLOSING_DATE,
            payload:{
                NextClosing:data
            }
        })
    })
    .catch((error=>console.error(error)))
}

export const GetNextDepreciationClosingDate=()=>(dispatch)=>{
    dispatch(getRequest("/ClosingDate/NextClosingDate",{ClosingTypeId:CLOSING_TYPE_DEPRECIATION_ID}))
    .then((resp)=>{
       const {payload:{data}}=resp;
        dispatch({
            type:GET_NEXT_Depreciation_CLOSING_DATE,
            payload:{
                NextClosing:data
            }
        })
    })
    .catch((error=>console.error(error)))
}




