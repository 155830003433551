import React from 'react'
import Entry from './Entry'
//import Report from './Report'
import {Switch,Route} from 'react-router-dom'
const PointOfSale=({match})=>(
        <Switch>
            <Route path={`${match.url}/Entry`} component={Entry}  /> 
        </Switch>
        //<Route path={`${match.url}/Report`} component={Report}  /> 
)
export default PointOfSale;