import React, { useEffect } from 'react'
import {Select} from 'antd'

const {Option} =Select;
const SelectDa=(({onChange,placeHolder,data,Value,toShow,Disabled,keyName,ShowFirst,Style,Size,Mode})=>{
    
    useEffect(()=>{
    },[Value])

    const Filter=(input, option) =>{
       return  (JSON.stringify(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0);
    }
   
    // const SelectFirst =  () => {

    //     const HasOneElement = data.length === 1;
    //     const FirstElementVal =  HasOneElement && typeof keyName==="string" ? data[0][""+keyName+""]: Value;
    //     const ResultValue =  typeof Value !=="undefined" ? Value: FirstElementVal;
    //     return ResultValue;
    // }
    return (
        <Select showSearch 
                value={Value} 
                size={Size}
                onChange={onChange}  
                placeholder={placeHolder}
                filterOption={Filter}
                disabled={Disabled}
                mode={Mode}
                style={Style}>
                     {ShowFirst &&
                       <Option value={undefined}>
                            {placeHolder}
                        </Option>
                        
                     } 
                         {
                            data.map((item)=>(
                                <Option key={(keyName===undefined)?item.id:item[keyName]} 
                                        value={(keyName===undefined)?item.id:item[keyName]}>
                                            {(item[toShow[0]] && item[toShow[0]]+toShow[1])} {item[toShow[2]]}
                                </Option>
                            ))
                         }
             </Select>
    )
})
SelectDa.defaultProps={
    onChange:()=>{},
    placeHolder:"-- Seleccione --",
    data:[],
    toShow:['code',' - ','description'],
    Disabled:false,
    keyName:undefined,
    ShowFirst:false,
    Mode:null,
    Style:{},
    Size:"middle"
}

export default SelectDa;